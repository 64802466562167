<template>

  <b-row
    style="height: 80vh"
  >
    <b-col
      v-for="(analysis, index) in processedRequestsAnalysis"
      :key="index"
      cols="4"
    >
      <ChartTable
        :colors1="colors1[index % colors1.length]"
        :colors2="colors2[index % colors2.length]"
        :source="`${analysis.source}`"
        :requests-analysis=" analysisWithoutSource(index) "
      />
    </b-col>
  </b-row>
</template>

<script>
import ChartTable from './components/ChartTable.vue'

export default {
  components: {
    ChartTable,
  },
  props: {
    requestsAnalysisData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dashboard: {},
      requestsAnalysis: this.requestsAnalysisData,
      source: '',
      colors1: [
        ['#33c9d4', '#57c1ce', '#51bfcc', '#4cbdc9', '#46bbc7', '#41b9c4', '#3bb7c2', '#36b5bf', '#30b3bd', '#2bb1ba'],
        ['#de7e23', '#f7b970', '#f7ae5a', '#f7a643', '#f79d2d', '#f78b16', '#f67e12', '#ef7110', '#ef5e0e', '#ed9339'],
        ['#ff0000', '#ff0000', '#ff0000', '#ff4d4d', '#ff6666', '#ff8080', '#ff9999', '#ffb3b3', '#ffcccc', '#7f0000'],
        ['#8c5a78', '#825172', '#825372', '#824e6c', '#966987', '#a8748d', '#754a65', '#7a4968', '#734864', '#401b35'],
        ['#6e1fd4', '#631cbf', '#5819aa', '#4d1694', '#42137f', '#37106a', '#2c0c55', '#210940', '#16062a', '#0b0315'],
      ],
      colors2: [
        ['#70dde0', '#69dbdf', '#62d9dd', '#5cd6dc', '#55d4db', '#4ed2d9', '#47d0d8', '#41cdd7', '#3acbd5', '#33c9d4'],
        ['#f7c486', '#fabd73', '#f7a44a', '#f29e44', '#eb963b', '#e88f35', '#e38227', ' #e38424', ' #e07f24', ' #de7e23'],
        ['#ff6600', '#ff5c00', '#ff5200', '#ff4700 ', '#ff3d00 ', '#ff3300', '#ff2900', '#ff2400', '#ff1f00', '#ff1a00', '#ff1400', '#ff0f00', '#ff0a00', '#ff0000', '#ff0000'],
        ['#c793aa', '#c48fa5', '#945d7a', '#945d7c', '#946280', '#996383', '#965f7c', '#996285', '#946380', '#8c5a78'],
        ['#99ccff', '#94b8fa', '#8fa3f5', '#8c99f2', '#8785ed', '#8270e8', '#7d5ce3', '#7847de', '#7333d9', '#6e1fd4'],
      ],
    }
  },
  computed: {
    processedRequestsAnalysis() {
      if (!this.requestsAnalysis || !this.requestsAnalysis.length) return []

      return this.requestsAnalysis
    },
  },
  methods: {
    analysisWithoutSource(index) {
      const { source, ...analysisWithoutSource } = this.requestsAnalysis[index]
      return analysisWithoutSource
    },
  },
}
</script>
