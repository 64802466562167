<template>
  <b-row
    v-if="loader"
    style="height: inherit"
    class="d-flex align-content-center justify-content-center"
    :style="'height: 80vh'"
  >
    <span class="d-flex flex-column align-items-center justify-content-center">
      <b-spinner
        type="grow"
        label="Loading..."
      />
      <p class="text-muted py-2">Loading...</p>
    </span>
  </b-row>

  <b-row
    v-else
    :style="'height: 80vh'"
    class="d-flex align-content-center justify-content-center"
  >
    <b-row
      v-if="!selectedComponent"
      class="mx-3"
    >
      <b-col cols="6">
        <b-card
          no-body
          class="overflow-hidden hover-card"
          style="height: 40vh;"
          @click="selectComponent('requests')"
        >
          <b-row
            no-gutters
            style="height: 100%;"
          >
            <b-col
              md="6"
              style="height: 100%;"
            >
              <b-card-img-lazy
                src="@/assets/images/analysis/request.jpg"
                style="height: 100%;"
                alt="Image"
                class="rounded-0"
              />
            </b-col>
            <b-col
              md="6"
              class="d-flex align-items-center justify-content-center"
            >
              <b-card-body title="Requests Analysis">
                <b-card-text>
                  This section provides an overview of user behavior. It includes insights on user engagement, frequency of interactions, and the characteristics of the user base. Key metrics like active users, user retention rate, and average session duration can also be highlighted here.
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="6">
        <b-card
          no-body
          class="overflow-hidden hover-card"
          style="height: 40vh;"
          @click="selectComponent('users')"
        >
          <b-row
            no-gutters
            style="height: 100%;"
          >
            <b-col
              md="6"
              style="height: 100%;"
            >
              <b-card-img-lazy
                src="@/assets/images/analysis/user.jpg"
                style="height: 100%;"
                alt="Image"
                class="rounded-0"
              />
            </b-col>
            <b-col
              md="6"
              class="d-flex align-items-center justify-content-center"
            >
              <b-card-body title="User Analysis">
                <b-card-text>
                  Here you can show the analysis of various requests. This section can include insights on request volume, types, and trends. You might also highlight specific metrics or key takeaways based on your analysis.
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row
      v-else
      class="mx-3 mt-2"
    >
      <b-col
        class="my-3"
        cols="12"
      >
        <button
          class="btn btn-primary mt-5"
          @click="selectedComponent = null"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </button>
      </b-col>

      <component
        :is="selectedComponent"
        :requests-analysis-data="requestsAnalysisData"
        :user-analysis-data="usersAnalysisData"
      />
    </b-row>
  </b-row>
</template>

<script>
import axios from 'axios'
import decryptData from '@/store/auth/decryptData'
import RequestsAnalysis from './RequestsAnalysis.vue'
import UsersAnalysis from './UsersAnalysis.vue'

export default {
  components: {
    RequestsAnalysis,
    UsersAnalysis,
  },
  data() {
    return {
      dashboard: {},
      requestsAnalysisData: [],
      usersAnalysisData: [],
      loader: false,
      selectedComponent: null, // Track selected component
    }
  },
  mounted() {
    this.getRequestsAnalysis()
  },
  methods: {
    getRequestsAnalysis() {
      this.loader = true
      axios.get('/analysis').then(res => {
        this.requestsAnalysisData = decryptData(res.data.payload).data?.analysis?.requests_analysis
        this.usersAnalysisData = decryptData(res.data.payload).data?.analysis?.users_analysis
      }).finally(() => {
        this.loader = false
      })
    },
    selectComponent(component) {
      // Set selectedComponent based on clicked card
      this.selectedComponent = component === 'requests' ? RequestsAnalysis : UsersAnalysis
    },
  },
}
</script>

<style>
  .hover-card {
    transition: transform 0.3s ease;
    cursor: pointer;
  }

  .hover-card:hover {
    transform: scale(1.05);
  }
</style>
