<template>
  <b-row style="height: 80vh; width: 80vw;">
    <b-col cols="12">
      <b-row>
        <b-col cols="6">
          <div class="d-flex justify-content-between align-items-center h-100 mb-2">
            <b-form-input
              v-model="searchQuery"
              placeholder="Search by Name Engineer..."
              class="w-100 h-50"
            />
          </div>

        </b-col>
        <b-col
          cols="6"
          class="d-flex justify-content-between"
        >
          <b-row class="w-100">
            <b-col cols="8">
              <div class="py-1 ">
                <label style="font-weight: bold; font-size: large; text-decoration: underline; color: #009df7;">Filter by Sources :</label>
              </div>
              <div class="mb-2 d-flex justify-content-between">
                <b-form-checkbox
                  v-model="selectedSources"
                  value="jeem1"
                >
                  Jeem1
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="selectedSources"
                  value="saber"
                >
                  Saber
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="selectedSources"
                  value="sfda"
                >
                  Sfda
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="selectedSources"
                  value="moiat"
                >
                  Moiat
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="selectedSources"
                  value="sls"
                >
                  Sls
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="py-1 ">
                <label style="font-weight: bold; font-size: large; text-decoration: underline; color: #009df7;">Filter by Late Requests:</label>
              </div>
              <div class="mb-2 d-flex justify-content-center">
                <b-form-checkbox
                  v-model="filterLateRequests"
                >
                  Late Requests
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </b-col>

      </b-row>

      <b-table
        v-if="filteredItems.length"
        class="table-container"
        sticky-header
        :items="filteredItems"
        style="min-height: 68vh; width: 100%;"
        striped
      >
        <template #head(index)>
          <span>#</span>
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }} <!-- Keeps the human-readable index -->
        </template>

        <template #head(Load)>
          <div class="d-flex align-items-center">
            Load
            <b-button
              variant="link"
              class="p-0 ml-1"
              :disabled="isDesc"
              style="color: white;"
              @click="setSortOrder(true)"
            >
              ▲
            </b-button>
            <b-button
              variant="link"
              class="p-0"
              style="color: white;"
              :disabled="!isDesc"
              @click="setSortOrder(false)"
            >
              ▼
            </b-button>
          </div>
        </template>

        <template #cell(Sources)="data">
          <div class="d-flex align-content-center justify-content-center">
            <div
              v-for="(value, key) in data.item.Sources"
              :key="key"
              class="mx-2"
            >
              <b-button
                variant="outline-primary"
                size="sm"
                style="width: fit-content; height: 5vh; text-align: center; margin: 5px 0px;"
                @click="showSourceData( data.item['Name Engineer'] ,key, value )"
              >
                {{ key }}
              </b-button>
            </div>
          </div>
        </template>

        <template #cell(Load)="data">
          {{ data.item.Load }}
        </template>
      </b-table>

      <div
        v-else
        class="text-center mt-3"
      >
        <h3>This engineer does not have Analysis</h3>
      </div>
    </b-col>

    <b-modal
      v-model="isModalVisible"
      :title="sourceData.name"
      hide-footer
    >
      <ChartTable
        :colors1="selectedColors1"
        :colors2="selectedColors2"
        :source="`${sourceData.source}`"
        :requests-analysis="sourceData.data "
      />
    </b-modal>
  </b-row>
</template>

<script>
import ChartTable from './components/ChartTable.vue'

export default {
  components: {
    ChartTable,
  },
  props: {
    userAnalysisData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isModalVisible: false,
      sourceData: '',
      isDesc: true, // Default sorting to high-to-low
      searchQuery: '', // Search query for filtering
      selectedSources: [], // Array to hold selected sources
      filterLateRequests: false,
      items: this.userAnalysisData.map(data => ({
        index: '',
        'Name Engineer': data.engineer_name || '',
        Load: data.total_load || '',
        Sources: data.sources || {},
      })),
      colors1: [
        ['#33c9d4', '#57c1ce', '#51bfcc', '#4cbdc9', '#46bbc7', '#41b9c4', '#3bb7c2', '#36b5bf', '#30b3bd', '#2bb1ba'],
        ['#de7e23', '#f7b970', '#f7ae5a', '#f7a643', '#f79d2d', '#f78b16', '#f67e12', '#ef7110', '#ef5e0e', '#ed9339'],
        ['#ff0000', '#ff0000', '#ff0000', '#ff4d4d', '#ff6666', '#ff8080', '#ff9999', '#ffb3b3', '#ffcccc', '#7f0000'],
        ['#8c5a78', '#825172', '#825372', '#824e6c', '#966987', '#a8748d', '#754a65', '#7a4968', '#734864', '#401b35'],
        ['#6e1fd4', '#631cbf', '#5819aa', '#4d1694', '#42137f', '#37106a', '#2c0c55', '#210940', '#16062a', '#0b0315'],
      ],
      colors2: [
        ['#70dde0', '#69dbdf', '#62d9dd', '#5cd6dc', '#55d4db', '#4ed2d9', '#47d0d8', '#41cdd7', '#3acbd5', '#33c9d4'],
        ['#f7c486', '#fabd73', '#f7a44a', '#f29e44', '#eb963b', '#e88f35', '#e38227', ' #e38424', ' #e07f24', ' #de7e23'],
        ['#ff6600', '#ff5c00', '#ff5200', '#ff4700 ', '#ff3d00 ', '#ff3300', '#ff2900', '#ff2400', '#ff1f00', '#ff1a00', '#ff1400', '#ff0f00', '#ff0a00', '#ff0000', '#ff0000'],
        ['#c793aa', '#c48fa5', '#945d7a', '#945d7c', '#946280', '#996383', '#965f7c', '#996285', '#946380', '#8c5a78'],
        ['#99ccff', '#94b8fa', '#8fa3f5', '#8c99f2', '#8785ed', '#8270e8', '#7d5ce3', '#7847de', '#7333d9', '#6e1fd4'],
      ],
    }
  },
  computed: {
    filteredItems() {
      // Filter items by searchQuery
      let filtered = this.items.filter(item => item['Name Engineer'].toLowerCase().includes(this.searchQuery.toLowerCase()))

      // Further filter by selectedSources
      if (this.selectedSources.length > 0) {
        filtered = filtered.filter(item => this.selectedSources.some(source => item.Sources[source]))
      }

      if (this.filterLateRequests) {
        filtered = filtered.filter(item => Object.values(item.Sources).some(source => source.late_requests > 0))
      }
      // Sort the filtered items
      return filtered.sort((a, b) => (this.isDesc ? b.Load - a.Load : a.Load - b.Load))
    },
    selectedColors1() {
      return this.getColorArray(this.colors1)
    },
    selectedColors2() {
      return this.getColorArray(this.colors2)
    },
  },
  // mounted() {
  //   console.log(this.userAnalysisData)
  // },
  methods: {
    showSourceData(name, key, value) {
      this.sourceData = {
        name,
        source: key,
        data: value,
      }
      this.isModalVisible = true
    },
    setSortOrder(descending) {
      this.isDesc = descending
    },
    getColorArray(colors) {
      const sourceIndexMap = {
        jeem1: 0,
        moiat: 1,
        saber: 2,
        sfda: 3,
        sls: 4,
      }
      const index = sourceIndexMap[this.sourceData.source]
      return colors[index] || colors[0] // Default to index 0 if the source is not found
    },
  },
}
</script>

<style lang="scss">
.table-container::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-thumb {
  background: #009df7;
  border-radius: 0px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #009df7;
}
.table-container  {
  box-shadow: 0 0px 3px 0 rgba(0 ,0 ,0 ,0.9);
  background-color: white !important;
  border: none !important;
table{
  thead {
    tr th {
      background-color: #009df7 !important ;
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
      color: white !important;
    }

  }
}
}
</style>
