<template>
  <div class="analysis-chart-table">
    <div class="header-table">
      <div
        class="chart-table-header"
        :style="
          `background-image: linear-gradient(to right, ${colors2[0]} , ${colors2[9]} )`
        "
      >
        <span />
      </div>
      <div
        class="chart-table-header-2"
        :style="
          `background-image: linear-gradient(to right, ${colors1[0]} , ${colors1[9]} )`
        "
      >
        <span>{{ source }}</span>
      </div>
    </div>

    <div class="chart-table">
      <div class="chart-table-body-left">
        <div
          v-for="(value, key) in requestsAnalysis"
          :key="key"
          class="chart-table-cell-left"
        >
          <span> {{ key.replaceAll("_", " ").toUpperCase() }}</span>
        </div>
      </div>

      <div class="chart-table-body-right">
        <div
          v-for="(value, key, index) in requestsAnalysis"
          :key="key"
          class="chart-table-cell-right"
          :style="`background-color : ${getColor(index)}`"
        >
          <span>{{ value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    colors1: {
      type: Array,
      required: true,
    },
    colors2: {
      type: Array,
      required: true,
    },
    requestsAnalysis: {
      type: Array,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
  },
  // mounted() {
  //   console.log(this.requests_analysis)
  // },
  methods: {
    getColor(index) {
      return this.colors2[index % this.colors2.length]
    },
  },
}
</script>
<style>
.header-table {
  display: table;
  width: 100%;
  border-spacing: 0;
  animation: header 4s ease-in-out;
}
@keyframes header {
  0% {
    transform: translateX(0%);
    opacity: 0;
  }
  50% {
    transform: translateX(-10%);
    opacity: 1;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
.chart-table-header,
.chart-table-header-2 {
  display: table-cell;
  padding: 40px 20px;
  text-align: center;
  vertical-align: middle;
  color: #e3fdff;
}

.chart-table-header {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.chart-table-header-2 {
  width: 80%;
  text-align: start;
  font-size: 3rem;
  font-weight: 100;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", Impact,
    Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

.chart-table {
  font-family: Arial, sans-serif;
  width: 100%;
  display: flex;
}
.chart-table-body-right,
.chart-table-body-left {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: transparent;
  padding: 0.3rem 0.2rem;
}

.chart-table-body-right {
  width: 20%;
}
.chart-table-body-left {
  width: 80%;
}

.chart-table-cell-right,
.chart-table-cell-left {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  text-align: center;
  height: 4rem;
}

.chart-table-cell-right {
  color: white;
  width: 100%;
  animation: cellRight 4s ease-in-out forwards;
}
@keyframes cellRight {
  0% {
    opacity: 0;
    transform: translateX(100%) rotateY(180deg);
  }
  25% {
    opacity: 0;
    transform: translateX(50%) rotateY(90deg);
  }
  50% {
    opacity: 0.5;
    transform: translateX(0) rotateY(0deg);
  }
  75% {
    opacity: 0.5;
    transform: translateX(0) rotateY(-90deg);
  }
  100% {
    opacity: 1;
    transform: translateX(0) rotateY(0deg);
  }
}

.chart-table-cell-left {
  background-color: #e8e8e6;
  color: #7a7472;
  width: 100%;
  font-weight: thick;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  animation: cellLeft 4s ease-in-out;
}
@keyframes cellLeft {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
